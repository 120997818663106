import { FetchService } from './fetch.service';
import cacheService from './cache.service';
import { OpeningHours, OpeningHoursRaw } from '../models/opening-hours.model';
import dateService from './date.service';
import refreshService from './refresh.service';
import environment from './environment';

const fetchService = new FetchService(environment.apiUrl);

export default {
  convertFromRaw(raw: OpeningHoursRaw): OpeningHours {
    return {
      ...raw,
      fromDate: new Date(raw.from * 1000),
      untilDate: new Date(raw.until * 1000),
      refreshDate: new Date(raw.refresh * 1000),
      timestampDate: new Date(raw.timestamp),
    };
  },
  mapToRefreshDate(hours: OpeningHours): Date {
    return hours.refreshDate;
  },
  setOffsetFromHours(hours: OpeningHoursRaw): OpeningHoursRaw {
    dateService.setServerOffset(hours.timestamp);
    return hours;
  },
  fetchHours(): Promise<OpeningHours> {
    return fetchService
      .fetch('/api/oeffnungszeiten/')
      .then(this.setOffsetFromHours.bind(this))
      .then(this.convertFromRaw.bind(this));
  },
  getHoursFromCacheOrFetch(): Promise<OpeningHours> {
    return cacheService.cache<OpeningHours>(
      'openingHours',
      this.mapToRefreshDate.bind(this),
      this.fetchHours.bind(this),
      ['fromDate', 'untilDate', 'refreshDate', 'timestampDate'],
    );
  },
  subscribeToHours(
    next: (hours: OpeningHours) => void,
    error: (reason: string) => void,
  ) {
    return refreshService.refresh(
      this.getHoursFromCacheOrFetch.bind(this),
      this.mapToRefreshDate.bind(this),
    )(next, error);
  },
};
