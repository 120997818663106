import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Box, Heading } from 'grommet';
import { Phone, Mail, Map } from 'grommet-icons';

import ActionButton from '../ActionButton';
import OpeningHours from './OpeningHours';
import AppContext from '../AppContext';
import EmergencyService from './EmergencyService';
import NukaCarousel from '../Carousel';
import IsSmaller from '../IsSmaller';
import { red, green, yellow } from '../../utils/colors';

export default () => (
  <IsSmaller than={768}>
    {({ isSmaller }) =>
      isSmaller ? (
        <NukaCarousel mobileBreakpoint={768} autoPlayInterval={3000}>
          <CarouselBox>
            <EmergencyCard />
          </CarouselBox>
          <CarouselBox>
            <ContactCard />
          </CarouselBox>
          <CarouselBox>
            <HoursCard />
          </CarouselBox>
        </NukaCarousel>
      ) : (
        <CardContainer>
          <ContactCard />
          <HoursCard />
          <EmergencyCard />
        </CardContainer>
      )
    }
  </IsSmaller>
);

const ContactCard = () => (
  <Card>
    <Heading level="4">Kontakt</Heading>
    <TopLink to="/kontakt/">
      <ContactTitle>Hirsch-Apotheke Korbach</ContactTitle>
      <ContactBody>
        Tel. 05631 / 25 45
        <br />
        Prof.-Kümmell-Straße 2<br />
        34497 Korbach
      </ContactBody>
    </TopLink>
    <Box direction="row">
      <ActionButton
        title="Kommen Sie vorbei!"
        href="https://www.google.de/maps/place/Hirsch+Apotheke/@51.2732954,8.8691444,17z/data=!3m1!4b1!4m5!3m4!1s0x47bb9e7d54800a37:0xc81ba2ddfc11c6!8m2!3d51.2732954!4d8.8713331?hl=de"
        Icon={Map}
        hoverColor={red}
      />
      <ActionButton
        title="Rufen Sie uns an!"
        href="tel:056312545"
        Icon={Phone}
        hoverColor={green}
      />
      <ActionButton
        title="Schreiben Sie uns eine E-Mail!"
        href="mailto:kontakt@hirsch-apotheke-korbach.de"
        Icon={Mail}
        hoverColor={yellow}
      />
    </Box>
  </Card>
);

const HoursCard = () => (
  <Card>
    <Heading level="4">Öffnungszeiten</Heading>
    <OpeningHours />
    <p>
      Montag - Freitag:
      <br />
      8:30 Uhr - 18:00 Uhr
      <br />
      Samstag:
      <br />
      9:00 Uhr - 13:00 Uhr
    </p>
  </Card>
);

const EmergencyCard = () => {
  return (
    <Card>
      <Heading level="4">Aktueller Notdienst</Heading>

      <EmergencyCardContent />
    </Card>
  );
};

const EmergencyCardContent = () => {
  const context = React.useContext(AppContext);

  if (!context.emergencyServicesByDistance) {
    return <p>Lädt...</p>;
  }

  const [es, ...moreEmergencyServices] =
    context.emergencyServicesByDistance?.current ?? [];
  const hasMore = moreEmergencyServices.length > 0;

  return (
    <>
      <EmergencyService service={es} key={es.pharmacy.name} link />
      {hasMore && (
        <TopLink to="/notdienst/#aktuell">
          <small>
            {moreEmergencyServices.length} weitere Notdienste in der Umgebung
          </small>
        </TopLink>
      )}
    </>
  );
};

const CardContainer = styled(Box)`
  flex-direction: column;
  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.small.value}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const CarouselBox = styled.div`
  padding-bottom: 50px;
`;

const Card = styled(Box).attrs({
  elevation: 'xsmall',
})`
  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.small.value}px) {
    flex-basis: 0;
    flex-grow: 1;
  }

  align-items: center;
  padding: 10px;
  margin: 7px;
  border-radius: 7px;
`;

const ContactTitle = styled.h5`
  margin-bottom: 10px;
`;

const ContactBody = styled.p`
  margin-bottom: 5px;
`;

const TopLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;
