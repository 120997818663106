import { createGlobalStyle } from 'styled-components';
import { headerHeight } from '../components/Layout/Header';

export default createGlobalStyle`
html {
  scroll-padding-top: ${headerHeight}px;
  scroll-behavior: smooth;
}  

p, ul, ol {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
`;
