import { min } from 'date-fns';
import { FetchService } from './fetch.service';
import cacheService from './cache.service';
import dateService from './date.service';
import refreshService from './refresh.service';
import {
  EmergencyServiceRaw,
  EmergencyService,
  EmergencyResponseRaw,
  EmergencyResponse,
} from '../models/emergency-service.model';
import environment from './environment';

const fetchService = new FetchService(environment.api2Url);

export default {
  convertServiceFromRaw(raw: EmergencyServiceRaw): EmergencyService {
    return {
      fromDate: new Date(raw.from),
      untilDate: new Date(raw.to),
      pharmacy: {
        name: raw.name,
        street: raw.street,
        zipCode: raw.zipCode,
        location: raw.location,
        subLocation: raw.subLocation,
        phone: raw.phone,
        lat: raw.lat,
        lon: raw.lon,
      },
    };
  },
  convertResponseFromRaw(rawResponse: EmergencyResponseRaw): EmergencyResponse {
    return {
      current: rawResponse.current.map(this.convertServiceFromRaw),
      upcoming: rawResponse.next.map(this.convertServiceFromRaw),
    };
  },
  mapServiceToRefreshDate(service: EmergencyService): Date {
    return service.untilDate;
  },
  mapResponseToRefreshDate(response: EmergencyResponse): Date {
    return min([
      ...response.current.map(this.mapServiceToRefreshDate),
      ...response.upcoming.map(this.mapServiceToRefreshDate),
    ]);
  },
  setOffsetFromResponse(response: EmergencyResponseRaw): EmergencyResponseRaw {
    dateService.setServerOffset(response.timestamp);
    return response;
  },
  fetchEmergency(): Promise<EmergencyResponse> {
    return fetchService
      .fetch('/notdienst/')
      .then(this.setOffsetFromResponse.bind(this))
      .then(this.convertResponseFromRaw.bind(this));
  },
  getEmergencyFromCacheOrFetch(): Promise<EmergencyResponse> {
    return cacheService.cache<EmergencyResponse>(
      'emergencyServices2',
      this.mapResponseToRefreshDate.bind(this),
      this.fetchEmergency.bind(this),
      ['fromDate', 'untilDate', 'stampDate'],
    );
  },
  subscribeToEmergency(
    next: (services: EmergencyResponse) => void,
    error: (reason: string) => void,
  ) {
    return refreshService.refresh(
      this.getEmergencyFromCacheOrFetch.bind(this),
      this.mapResponseToRefreshDate.bind(this),
    )(next, error);
  },
};
