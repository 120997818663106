import environment from './environment';

export default {
  fetch(api: string) {
    return fetch(environment.apiUrl + api).then((response: Response) =>
      response.json(),
    );
  },
};

export class FetchService {
  private apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  fetch(api: string) {
    return fetch(this.apiUrl + api).then((response: Response) =>
      response.json(),
    );
  }
}
