import React, { useState, useEffect, ReactNode } from 'react';
import { getDistance } from 'geolib';

import { OpeningHours } from '../models/opening-hours.model';
import {
  EmergencyResponse,
  EmergencyService,
} from '../models/emergency-service.model';
import openingHoursService from '../services/opening-hours.service';
import emergencyServicesService from '../services/emergency-services.service';
import onError from '../utils/on-error';

export interface AppContextValue {
  openingHours?: OpeningHours;
  emergencyServices?: EmergencyResponse;
  emergencyServicesByDistance?: EmergencyResponse;
}
const AppContext = React.createContext<AppContextValue>({});

const DISTANCE_ORIGIN = {
  latitude: 51.2728581,
  longitude: 8.8707098,
};

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [openingHours, setOpeningHours] = useState<OpeningHours>();
  const [emergencyServices, setEmergencyServices] =
    useState<EmergencyResponse>();

  useEffect(() => {
    openingHoursService.subscribeToHours(setOpeningHours, onError);
    emergencyServicesService.subscribeToEmergency(
      setEmergencyServices,
      onError,
    );
  }, []);

  const emergencyServicesByDistance = React.useMemo(() => {
    if (!emergencyServices) {
      return undefined;
    }

    const currentSorted = emergencyServices.current.toSorted(
      compareDistanceToOrigin,
    );
    const upcomingSorted = emergencyServices.upcoming.toSorted(
      compareDistanceToOrigin,
    );

    return {
      current: currentSorted,
      upcoming: upcomingSorted,
    };
  }, [emergencyServices]);

  return (
    <AppContext.Provider
      value={{
        openingHours,
        emergencyServices,
        emergencyServicesByDistance,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

function compareDistanceToOrigin(
  a: EmergencyService,
  b: EmergencyService,
): number {
  const aDistance = getDistanceToOrigin(
    parseFloat(a.pharmacy.lat),
    parseFloat(a.pharmacy.lon),
  );
  const bDistance = getDistanceToOrigin(
    parseFloat(b.pharmacy.lat),
    parseFloat(b.pharmacy.lon),
  );

  return aDistance - bDistance;
}

function getDistanceToOrigin(lat: number, lon: number): number {
  return getDistance(
    {
      latitude: lat,
      longitude: lon,
    },
    DISTANCE_ORIGIN,
  );
}
